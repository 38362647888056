import React from 'react'
import Layout from '../components/layout'
import PageStructure from '../components/pagestructure'
import SEO from '../components/seo'
import IMGAbout from '../images/aboutus_c.jpg'

const content = {}
const Structure = () => {
  return (
    <div>
      <Layout>
        <SEO title="About US" />
        <PageStructure
          title="About Us"
          desc="TESTIG"
          mode="aboutus"
          image={IMGAbout}
          content={`
          <br/><br/>
          Colloidle systems was founded in 2018. We started our journey by building a custom travel solution for one of our clients which is used in the visa processing and visa delivery.
          <br/><br/>
          We are the team of experts in different domains having the best mentors backing this company. 
          <br/><br/>
          Then we started developing our own communication app which first included the webmail. This application will help users to compose and read the mail. The main feature of this application is that users don't need to configure their mail servers.
          <br/><br/>
          We are also building the application as per the requirement. We already deployed the cutting edge solutions for our clients. This varies from mobile to cloud application.
          <br/><br/>
          Our vision is to build the best communication apps for the society to connect them seamlessly with each other and meet the new people around them.
          <br/><br/>
          `}
        />
      </Layout>
    </div>
  )
}

export default Structure
